// --------------------------------
//  COLORS
// --------------------------------
// names taken from style guide

$brand-primary: #000;
$brand-secondary: #e6007e;
$black: #000;
$dark-gray: #393939;
$medium-gray: #757575;
$light-gray: #ababab;
$lighter-gray: #f8f3f2;
$white: #fff;
$accent1: #e6007e; // used in account, badges
$accent2: #00a4ed;
$accent3: #e6007e; // Add to Cart button
$accent4: #830048; // Add to Cart hover
$trans: transparent;

// consistent messaging colors
$error-color: #c00;
$warning-color: #f93; // used in account
$success-color: #008a00; // used in account

$logo-width-small: 100px;
$logo-height-small: 40px;
$logo-width-tablet: 125px;
$logo-height-tablet: 50px;
$logo-width-desktop: 13.9%; // 200px in 1440
$logo-height-desktop: 72px;

// top banner
$promo-gradient-bg: $brand-primary;

// add to cart modal banner
$promo-gradient-bg-right: $brand-primary;

// Aliased variables used in global
$site-input-border-radius: 4px;
$site-btn-border-radius: 40px;

// Button Fonts
$button-font: $sans-serif;
$label-font: $sans-serif;

// offset for the header search per brand
$header-search-offset: -70px;

// Increase all font-size values for BB by 2px
$body-text-size: 16px;
$body-line-height: 20px;
