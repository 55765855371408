@use "sass:color";

.br-m { display: none; }
.br-l { display: none; }
.br-xl { display: none; }
.br-s { display: block; }

@media screen and (min-width: 480px) {
    .br-s { display: none; }
    .br-l { display: none; }
    .br-xl { display: none; }
    .br-m { display: block; }
}

@media screen and (min-width: 768px) {
    .br-s { display: none; }
    .br-m { display: none; }
    .br-xl { display: none; }
    .br-l { display: block; }
}

@media screen and (min-width: 1025px) {
    .br-s { display: none; }
    .br-m { display: none; }
    .br-l { display: none; }
    .br-xl { display: block; }
}

.account-nav-desktop-top {
    .level-1-header {
        &:hover:not(.welcome-user),
        &.active {
            background-color: color.adjust($accent1, $lightness: -10%);
        }
    }
}

button.button-fancy-large {
    background: #eb0000;
    border-color: #eb0000;

    &:hover {
        background: #f90404;
    }
}
