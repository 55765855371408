// --------------------------------------
// Link Styles
// --------------------------------------

@mixin type-lone-link {
    overflow: hidden;
    color: $link-color;
    text-decoration: $link-decor;

    &:hover {
        color: $link-hover-color;
        text-decoration: $link-hover-decor;
    }

    &.button {
        text-decoration: none;
    }
}

.lone-link {
    @include type-lone-link;

    &.payment-failuremessage-link {
        margin-left: 5px;
        margin-right: 5px;
    }
}

// Clear Refinement Link Styles
@mixin type-clear-refinement-link {
    @include font-reg;
}

@mixin type-back-link {
    @include font-bold;

    &::before {
        content: "";
        display: inline-block;
        background: $left-arrow;
        position: relative;
        top: 5px;
        width: 20px;
        height: 20px;
    }
}

.back {
    @include type-back-link;
}

// --------------------------------------
// Secondary Section Styles
// --------------------------------------
@mixin type-secondary-section {
    font-size: 1.1em;
}

// --------------------------------------
// Quantity Module Styles
// --------------------------------------

@mixin type-quantity-input {
    @include type-h5;
}
