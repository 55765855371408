// --------------------------------------
// Heading Styles
// --------------------------------------

// Most font sizes are now relative to $body-text-size (15px by default)

// Hero Heading
@mixin type-hero() {
    font-family: $hero-heading-font;
    font-weight: 500;

    @include font-size($body-text-size + 33px);

    color: $brand-primary;

    @include media-breakpoint-up(sm) {
        @include font-size($body-text-size + 39px);
    }

    @include media-breakpoint-up(md) {
        @include font-size($body-text-size + 57px);

        letter-spacing: -0.6px;
    }
}

.type-hero {
    @include type-hero;

    font-family: "Brandon Grotesque", arial, sans-serif;
    font-weight: 500;
    letter-spacing: 0;
}

// H1 Styles
@mixin type-h1 {
    font-family: $h1-heading-font;
    font-weight: 500;

    @include font-size($body-text-size + 15px);

    @include media-breakpoint-down(lg) {
        letter-spacing: 0.2px;
    }

    @include media-breakpoint-up(md) {
        @include font-size($body-text-size + 25px);
    }
}

// Secondary Column H1 Styles
@mixin type-h1-secondary {
    @include font-reg(1.75em);
}

// H2 Styles
@mixin type-h2 {
    font-family: $h2-heading-font;

    @include font-size($body-text-size + 9px);

    @include media-breakpoint-up(md) {
        @include font-size($body-text-size + 15px);
    }

    letter-spacing: 0;
}

// H3 Styles
@mixin type-h3 {
    font-family: $h3-heading-font;
    font-weight: 700;

    @include font-size($body-text-size + 5px);

    @include media-breakpoint-up(md) {
        @include font-size($body-text-size + 9px);
    }

    letter-spacing: 0;
}

// H4 Styles
@mixin type-h4 {
    font-family: $h4-heading-font;

    @include font-size($body-text-size + 3px);

    @include media-breakpoint-down(lg) {
        letter-spacing: 0.2px;
    }

    @include media-breakpoint-up(md) {
        @include font-size($body-text-size + 5px);
    }

    letter-spacing: 0;
}

// H5 Styles
@mixin type-h5 {
    font-family: $h5-heading-font;
    font-weight: 700;

    @include font-size($body-text-size + 1px);

    letter-spacing: 0;
}

// H6 Styles
@mixin type-h6 {
    font-family: $h6-heading-font;
    font-weight: 700;

    @include font-size($body-text-size);

    letter-spacing: 0;
}

// Content Header Styles
@mixin type-content-header {
    @include type-h5;
}

// Content Header Span Styles
@mixin type-content-header-inner {
    @include font-reg(0.75em);

    font-style: normal;
}

// Section Header Styles
@mixin type-section-header {
    @include type-h5;
}

// Section Header Note Styles
@mixin type-section-header-note {
    @include font-reg(0.9em);

    text-transform: none;
}

// Table Section Header Styles
@mixin type-table-section-header {
    font-size: 1em !important;
}

// Refinement Header Styles
@mixin type-refinement-header {
    @include font-bold($body-text-size);

    letter-spacing: 0.2px;
}

// Secondary Navigation Header
@mixin type-secondary-nav-header {
    @include type-refinement-header;
}

// Recommendations Header Styles
@mixin type-recommendations-header {
    @include font-reg(2em);
}

// Recommendation Tooltip Header Styles
@mixin type-recommendation-tooltip-header {
    @include font-bold(1.2em);
}

// Compare Table Section Header Styles
@mixin type-compare-table-section-header {
    text-transform: none;
    font-size: 0.9em;
}

@mixin type-hero-banner-heading {
    @include font-size($body-text-size + 27px);

    @include media-breakpoint-up(md) {
        @include font-size($body-text-size + 45px);
    }
}

.type-hero-banner-heading {
    @include type-hero-banner-heading;
}

@mixin type-hero-banner-sub-heading {
    @include font-size($body-text-size + 9px);

    @include media-breakpoint-up(md) {
        @include font-size($body-text-size + 19px);
    }
}

.type-hero-banner-sub-heading {
    @include type-hero-banner-sub-heading;
}

@mixin type-asset-tile-heading-inner {
    @include type-h2;

    @include media-breakpoint-up(md) {
        @include font-size($body-text-size + 29px);
    }
}

.type-asset-tile-heading-inner {
    @include type-asset-tile-heading-inner;
}

@mixin type-asset-tile-heading-outer {
    @include type-h4;

    @include media-breakpoint-up(md) {
        @include font-size($body-text-size + 7px);
    }
}

.type-asset-tile-heading-outer {
    @include type-asset-tile-heading-outer;
}
