////////////////////////////////////////////////////////////
// BUTTON MIXINS
////////////////////////////////////////////////////////////

/* button()
 * @description Insert button styling. Default (no param) will return primary button styles. If a param is passed, certain style rules are ommitted initially, as they would just be immediately overridden.
 * @param $class - String. Optional. The desired button style, ex: secondary. Acceptable values: 'primary' (default), 'primary-alt', 'secondary', 'secondary-alt', 'tertiary'
 * @example: a.my-link {@include button('secondary-alt')}
 */
@mixin button($class: "", $height: $btn-height, $fontsize: $btn-font-size) {
    @include set-button-height($height);
    @include type-h5;

    display: inline-block;
    transition: $btn-transition;
    box-sizing: border-box;
    border-width: $btn-border-width;
    border-style: solid;
    border-radius: $btn-border-radius;
    letter-spacing: $btn-letter-space;
    text-align: center;
    text-decoration: none;
    text-transform: $btn-text-transform;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active,
    &.active {
        text-decoration: none;
    }

    &:focus {
        outline: $focus-outline-style;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        cursor: default;
        font-family: $btn-disabled-font;
        opacity: $btn-disabled-opacity;
        background: $btn-disabled-bg;
        color: $btn-disabled-color;
        border-color: $btn-disabled-border-color;

        &:hover {
            background: $btn-disabled-bg;
            border-color: $btn-disabled-border-color;
            color: $btn-disabled-color;
        }
    }

    @if $class == "" {
        @include primary-button;
    }

    @if $class == "secondary" {
        @include secondary-button;
    }

    @if $class == "tertiary" {
        @include tertiary-button;
    }
}

// This override is only to be used if a button that is initially styled with secondary/tertiary/non-primary styling
// must be overrided with primary styles, for example in content banners.

@mixin primary-button() {
    border-color: $btn-border-color;
    background-color: $btn-bg;
    color: $btn-color;

    &:hover,
    &:focus,
    &:active,
    &.active {
        border-color: $btn-hover-border-color;
        background-color: $btn-hover-bg;
        color: $btn-hover-color;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &:hover,
        &:focus,
        &:active,
        &.active {
            font-family: $btn-disabled-font;
            background: $btn-disabled-bg;
            border-color: $btn-disabled-border-color;
            color: $btn-disabled-color;
        }
    }
}

// button styles overrides for secondary buttons

@mixin secondary-button() {
    font-family: $btn2-font;
    border-color: $btn2-border-color;
    background-color: $btn2-bg;
    color: $btn2-color;

    &:hover,
    &:focus,
    &:active,
    &.active {
        border-color: $btn2-hover-border-color;
        background-color: $btn2-hover-bg;
        color: $btn2-hover-color;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        background: $btn2-disabled-bg;
        border-color: $btn2-disabled-border-color;
        color: $btn2-disabled-color;

        &:hover,
        &:focus,
        &:active,
        &.active {
            background: $btn2-disabled-bg;
            border-color: $btn2-disabled-border-color;
            color: $btn2-disabled-color;
        }
    }
}

// button styles overrides for tertiary buttons

@mixin tertiary-button() {
    font-family: $btn3-font;
    border-color: $btn3-border-color;
    background-color: $btn3-bg;
    color: $btn3-color;

    &:hover,
    &:focus,
    &:active,
    &.active {
        border-color: $btn3-hover-border-color;
        background-color: $btn3-hover-bg;
        color: $btn3-hover-color;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        background: $btn3-disabled-bg;
        border-color: $btn3-disabled-border-color;
        color: $btn3-disabled-color;

        &:hover,
        &:focus,
        &:active,
        &.active {
            background: $btn3-disabled-bg;
            border-color: $btn3-disabled-border-color;
            color: $btn3-disabled-color;
        }
    }
}

// button styles overrides for error buttons

@mixin error-button() {
    border-color: $btn-error-border-color;
    background-color: $btn-error-bg;
    color: $btn-error-color;

    &:hover,
    &:focus,
    &:active,
    &.active {
        border-color: $btn-error-hover-border-color;
        background-color: $btn-error-hover-bg;
        color: $btn-error-hover-color;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &:hover,
        &:focus,
        &:active,
        &.active {
            border-color: $btn-error-border-color;
            background-color: $btn-error-bg;
            color: $btn-error-color;
        }
    }
}

// button styles overrides for large button variant

@mixin large-button() {
    @include set-button-height($lg-btn-height, $lg-btn-side-padding);
    @include type-h4;

    text-transform: $btn-text-transform;
}

// button styles overrides for simple button variant

@mixin simple-button() {
    background: $btn2-bg;
    color: $btn2-color;
}

// button styles overrides for Add To Cart sized buttons

@mixin add-to-cart-button() {
    min-height: $a2c-btn-height;

    @include type-h4;

    text-transform: $btn-text-transform;
    white-space: nowrap;
    background: $a2c-bg;
    border-color: $a2c-bg;

    &:hover {
        background: $a2c-bg-hover;
        border-color: $a2c-bg-hover;
    }

    &.disabled {
        font-family: $btn-disabled-font;
        background: $btn-disabled-bg;
        border-color: $btn-disabled-border-color;
        color: $btn-disabled-color;
    }
}

// Responsive buttons (for mobile)

@mixin responsive-button($width) {
    width: 100%;

    @include media-breakpoint-up(md) {
        width: $width;
        padding-left: 0;
        padding-right: 0;
    }
}

///////////// BUTTON STYLES ///////////////

button,
.button,
.buttonstyle,
input[type="button"] {
    @include button;

    &.primary {
        @include primary-button;
    }

    &.secondary {
        @include secondary-button;

        &.ordersearch {
            padding: 10px;
            margin: 0 10px 10px;

            &.ordersearchguest {
                margin: 10px;
            }

            @include media-breakpoint-down(md) {
                width: 100%;

                &.ordersearchguest {
                    margin: 10px 10px 10px 0;
                }
            }
        }
    }

    &.tertiary {
        @include tertiary-button;
    }

    &.error {
        @include error-button;
    }

    &.large {
        @include large-button;
    }

    &.simple {
        @include simple-button;
    }

    a {
        text-decoration: none;
        color: $btn-color;

        &:hover {
            text-decoration: none;
            color: $btn-hover-color;
        }
    }

    i {
        &.left {
            margin-right: 0.6em;
        }

        &.right {
            margin-left: 0.6em;
        }
    }
}

#add-to-cart,
.button-fancy-large {
    line-height: 1.5em;

    @include add-to-cart-button;
}

.button-fancy-large,
button {
    display: inline-block;
}

// Force a <button> to display like a regular <a> text link
@mixin button-text() {
    appearance: inherit;
    height: auto;
    min-height: 0;
    width: auto;
    min-width: auto; // IE
    min-width: initial;
    line-height: inherit;
    padding: 0;
    border-width: 0;
    letter-spacing: inherit;
    text-decoration: $link-decor;
    text-transform: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-style: inherit;
    background-color: $trans;
    color: $link-alt-color;

    &:hover,
    &:active,
    &:focus {
        text-decoration: $link-hover-decor;
        background-color: $trans;
        color: $link-hover-color;
    }

    p & {
        color: $link-color;

        &:hover,
        &:active,
        &:focus {
            color: $link-hover-color;
        }
    }
}

.button-text {
    @include button-text;
}

.button-full-width {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
}
