// -----------------------------
// Form Components
// -----------------------------

// buttons
@import "buttons";

legend {
    @include type-legend;

    position: relative;
    width: 96%;
    padding: 1.16em 2% 0.83em;
    border-bottom: 1em solid $base-bg;
    margin: 0;
    background: $section-header-bg;

    span {
        @include type-legend-inner;

        position: relative;
        padding: 0 0.5em;

        em {
            color: $body-text-color;
        }
    }
}

.dialog-required {
    @include type-dialog-required;

    display: inline;
    position: relative;
    padding: 0.16em 0;

    em {
        @include type-dialog-required-inner;

        color: $body-text-color;
    }
}

.required-indicator {
    @include type-required-indicator;

    margin-left: 0.25em;
}

label {
    @include type-label;

    display: block;
    margin-bottom: rem(7px); // 8px on screen
    color: $label-color;

    .form-horizontal & {
        float: left;
        width: 100%;
        margin-top: 0;
        margin-bottom: rem(5px);
    }

    .label-inline &,
    .field-wrapper &,
    .label-above &,
    .form-inline &,
    .row-inline & {
        width: auto;
    }

    .label-inline &,
    .field-wrapper & {
        @include type-label-inline;

        display: inline;
        float: none;
        margin-top: 0;
        text-align: left;
    }

    .label-above & {
        margin-bottom: 0.3rem;
    }

    .error-icon {
        @include type-error-icon;
    }

    &.error {
        .error-icon {
            display: inline-block;
        }
    }

    .error & {
        .error-icon {
            display: inline-block;
        }
    }
}

.form-label-text {
    @include type-label-text;

    float: left;
    width: 25%;
    padding: 0.75em 0 0;
    text-align: right;
}

.form-row {
    @include clearfix;

    margin: 0 0 rem(20px);

    &.error label {
        color: $error-color;
    }
}

.has-tooltip .field-wrapper {
    width: 72%;
    float: left;
}

.has-tooltip-max-width {
    position: relative;

    .form-field-tooltip {
        position: absolute;
        top: -10px;
        left: rem(45px);
    }

    @include media-breakpoint-up(lg) {
        .field-wrapper {
            width: 94%;
            float: left;
        }

        .form-field-tooltip {
            position: relative;
            top: 0;
            left: 0;
        }
    }
}

.field-wrapper {
    box-sizing: border-box;
    width: 100%;

    @include media-breakpoint-up(md) {
        width: 50%;
    }

    .form-horizontal & {
        float: left;
        width: 100%;

        @include media-breakpoint-up(md) {
            width: 50%;
        }
    }

    .label-inline &,
    .label-above & {
        width: auto;
        padding-left: 0;
    }

    .label-inline & {
        float: left;
    }

    .label-above & {
        float: none;
    }
}

// override number input spinner appearance
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
}

input[type="number"] {
    appearance: textfield;
}

input[type="text"]::-ms-clear {
    display: none;
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="date"],
textarea {
    @include type-input;

    box-sizing: border-box;
    width: 100%;
    height: $input-height;
    padding: 0 $input-padding-horizontal;
    border: $input-border;
    border-radius: $input-border-radius;
    text-indent: 0;
    background: $input-bg;
    color: $input-text-color;

    &:focus {
        border: $focus-border;
        outline: $focus-outline-style;
    }

    &::input-placeholder {
        color: $input-placeholder-color;
    }

    &:placeholder,
    &::placeholder { /* FF 18- */
        color: $input-placeholder-color;
    }

    &:input-placeholder {
        color: $input-placeholder-color;
    }
}

textarea {
    max-width: 100%;
    height: $textarea-height;
}

select {
    @include type-select;

    box-sizing: border-box;
    width: 100%;
    height: $input-height;
    border: $input-border;
    border-radius: $input-border-radius;
    background-color: $input-bg;
    color: $input-text-color;

    &:focus {
        border: $focus-border;
        outline: $focus-outline-style;
    }
}

/* ------ Select Box Styles: danielneumann.com Method (customized to iRobot) ------ */

/* ------ Source: http://danielneumann.com/blog/how-to-style-dropdown-with-css-only/ ------ */
%select-style {
    display: block; // set display to inline-block to allow a select box to shirnk down to it's natural width (rather than 100%)
    overflow: hidden;
    box-sizing: border-box;
    padding-right: $dropdown-arrow-icon-width * 2;
    border: $input-border;
    border-radius: $input-border-radius;
    background: $input-bg url("../images/svg-icons/select-arrow.svg") no-repeat scroll;
    background-position: right 10px center;

    // Hack: IE10 only
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        padding-right: 10px;
    }

    // Hack: IE9 only
    @media all and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
        padding-right: 0;
    }

    select {
        appearance: none;
        width: 120%; // in case some browser doesn't support the calc function below
        width: calc(100% + #{$dropdown-arrow-icon-width * 2});
        height: $input-height - $input-border-width * 2;
        padding: 2px $dropdown-arrow-icon-width * 2 2px 10px;
        border: none;
        background: $trans;
        text-overflow: ellipsis;

        &::-ms-expand {
            display: none;
        }

        // Hack: IE9 only
        @media all and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
            padding-right: 10px;
        }
    }

    label {
        display: none;
    }

    span {
        display: block;
        line-height: $input-height - $input-border-width * 2;
        cursor: default;
    }
}

.select-style {
    @extend %select-style;
}

input[type="checkbox"],
input[type="radio"] {
    opacity: 0;
    position: absolute;

    &:focus + label::before {
        border: $focus-border;
        outline: $focus-outline-style;
    }

    &:focus-visible + label::before {
        border: 2px solid $tab-selection-color !important;
        border-radius: 2px;
    }

    & + label:not(.bv-fieldset-label-checkbox) {
        position: relative;

        @include font-reg;

        &::before {
            content: "";
            display: inline-block;
            width: 16px;
            height: 16px;
            border: $input-border;
            margin-right: 8px;
            vertical-align: text-bottom;
            text-align: center;
            background: $input-bg; // should not be $trans
        }
    }

    & + label.refinement-link.checkbox-link {
        &::before {
            margin-left: rem(-35px);
        }
    }
}

input[type="checkbox"] {
    &:checked + label {
        @include font-bold;
    }

    &:checked + label::before {
        background: url("../images/svg-icons/checkbox-selected.svg") no-repeat center;
        border-color: $input-border-selected-color;
    }

    .sr-only {
        position: absolute;
        clip: rect(1px, 1px, 1px, 1px);
        padding: 0;
        border: 0;
        height: 1px;
        width: 1px;
        overflow: hidden;
    }
}

span .personalized-check {
    opacity: 1;
    position: relative;
}

input[type="radio"] {
    & + label {
        display: block;
        clear: both;
    }

    .options-inline & + label {
        display: inline;
        clear: none;

        &:nth-of-type(n + 2)::before { // all but the first
            margin-left: rem(32px);
        }
    }

    & + label::before {
        border-radius: 50%;
    }

    &:checked + label {
        @include font-bold;
    }

    &:checked + label::before {
        background: $trans url("../images/svg-icons/radio-selected.svg") no-repeat center;
        border-color: $input-border-selected-color;
    }

    &[disabled] + label {
        color: $light-gray;
    }
}

.char-count {
    @include type-form-caption;
}

.form-caption {
    @include type-form-caption;

    clear: left;
    width: auto;

    .checkbox & {
        @include type-checkbox-form-caption;

        text-indent: rem(32px);
    }

    .form-horizontal & {
        @include media-breakpoint-up(md) {
            @include type-horizontal-form-caption;
        }
    }

    .label-above & {
        margin-left: 0;
    }

    .form-indent & {
        margin-left: 0;
    }

    &.error-message {
        @include type-form-caption-error-message;

        padding: 0;
        width: inherit;
        color: $error-color;
    }
}

.form-indent {
    @include media-breakpoint-up(md) {
        margin-left: 27%;

        .field-wrapper {
            width: 80%;
            padding-left: 0;
        }
    }
}

.error-message {
    width: 49.5966%;
    padding: 0.7em 0.5em;
    text-indent: 0;
}

.error {
    color: $error-color;
}

input.error {
    border: $error-border;
}

span.error,
div.error:not(.form-row) {
    @include type-form-error;

    background: none;
}

.error-form {
    color: $error-color;
    margin: rem(10px) 0;
    background-color: $box-bg;

    &.billing-password-error {
        background-color: inherit;
    }

    // to give the icon space when text wraps
    display: flex;

    .icon {
        padding-right: rem(8px);
    }
}

.form-inline {
    form {
        width: 98%;
        padding: 0 0 2em;
    }

    input[type="text"],
    select {
        margin-left: 0;
        width: 95%;
    }

    button {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }
}

.row-inline {
    display: inline;
}

.form-field-tooltip {
    @include type-field-tooltip;

    float: left;
    width: 27%;
    margin-left: 1%;
}

.js {
    .simple-submit {
        display: none;
    }
}

.ui-datepicker {
    .ui-datepicker-next,
    .ui-datepicker-prev {
        top: 50%;
        transform: translateY(-50%);
    }

    .ui-datepicker-next {
        background: url("../images/svg-icons/arrow-block-right.svg") no-repeat center;

        &:hover {
            background: url("../images/svg-icons/arrow-block-right.svg") no-repeat center;
        }
    }

    .ui-datepicker-prev {
        background: url("../images/svg-icons/arrow-block-left.svg") no-repeat center;

        &:hover {
            background: url("../images/svg-icons/arrow-block-left.svg") no-repeat center;
        }
    }
}

div.form-row.wrapper-addToEmailList.checkbox > div.field-wrapper > label > span:nth-child(2) {
    text-transform: none;
}
