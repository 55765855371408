// --------------------------------------
// Breadcrumb Styles
// --------------------------------------

@mixin type-breadcrumb {
    font-size: rem($link-breadcrumb-font-size);
    letter-spacing: $link-breadcrumb-letter-spacing;
}

@mixin type-breadcrumb-last {
    font-family: $font-breadcrumb-active;
    font-weight: 700;
}

@mixin type-breadcrumb-refinement {
    @include font-reg;
}

@mixin type-breadcrumb-refinement-divider {
    font-style: normal;
}

@mixin type-breadcrumb-relax {
    font-style: normal;
}
